import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./contact.css";
import Form from "./Form.jsx";
import Map from "./Map.jsx";

function Contact() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <section id="contact" className="mt-10 bg-base-100 pt-16 pb-10">
      <div
        className="min-h-1 flex flex-col items-center justify-center"
        data-aos="fade-up"
        data-aos-duration="800"
      >
        <div
          className="bg-white shadow-md rounded-lg p-8 max-w-6xl w-full"
          data-aos="zoom-in"
          data-aos-duration="800"
          data-aos-delay="200"
        >
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div
              className="bg-gray-50 p-6 rounded-lg shadow"
              data-aos="fade-right"
              data-aos-duration="800"
              data-aos-delay="400"
            >
              <h2 className=" text-center text-2xl font-semibold text-gray-800 mb-4">
                Contact
              </h2>
              <Form />
            </div>
            <div
              className="bg-gray-50 p-6 rounded-lg shadow"
              data-aos="fade-left"
              data-aos-duration="800"
              data-aos-delay="600"
            >
              <h2 className="text-center text-2xl font-semibold text-gray-800 mb-2">
                Map
              </h2>
              <Map />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
