import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./about.css";
import myImage from "../../assets/Meeting.gif";

function About() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    AOS.init();
  }, []);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <section id="about" className="mt-10 bg-base-100 pt-16 pb-10">
      <div
        className="min-h-1 flex flex-col items-center justify-center"
        data-aos="fade-up"
        data-aos-duration="800"
      >
        <div
          className="bg-white shadow-md rounded-lg p-8 max-w-6xl w-full"
          data-aos="zoom-in"
          data-aos-duration="800"
          data-aos-delay="200"
        >
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div
              className="bg-gray-50 p-6 rounded-lg shadow"
              data-aos="fade-right"
              data-aos-duration="800"
              data-aos-delay="400"
            >
              <h2
                className="text-2xl text-center font-semibold text-gray-800 mt-10 mb-4"
                data-aos="zoom-in"
                data-aos-duration="800"
                data-aos-delay="600"
              >
                LAKE OF DREAMS IS A SPECIAL PLACE
              </h2>
              <p
                className="text-gray-700 text-lg mt-5 mb-6 text-center"
                data-aos="zoom-in"
                data-aos-duration="800"
                data-aos-delay="800"
              >
                – quiet, serene and the real "home" of our family. Built in 1996
              </p>
              <div
                className="flex items-center justify-center mt-5"
                data-aos="zoom-in"
                data-aos-duration="800"
                data-aos-delay="1000"
              >
                <button
                  onClick={openModal}
                  className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded hover:scale-110 transition duration-300 ease-in-out shadow-md"
                >
                  History of LOD
                </button>
              </div>
            </div>
            <div
              className="flex"
              data-aos="fade-left"
              data-aos-duration="800"
              data-aos-delay="400"
            >
              <img
                src={myImage}
                alt="Our team working together"
                className="rounded-image"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div className="bg-white rounded-lg shadow-xl max-w-2xl w-full">
            <div className="p-4 max-h-96 overflow-y-auto">
              <p className="my-4 text-slate-800 text-2xl text-bold leading-relaxed font-semibold">
                Lake of Dreams –
              </p>
              <p className="my-4 text-slate-500 text-lg leading-relaxed">
                quiet, serene and the real "home" of our family. Built in 1996,
                we were attracted to the site as our family became avid
                recreational water sport enthusiasts. The recreational aspect
                became more serious when our neighbors began coaching our
                youngest sons to become competitive water skiers before the age
                of 10. Since then, water skiing has become a huge part of our
                family life and Lake of Dreams has hosted many clinics, camps
                and competitions for kids throughout North America. In 2003, the
                serenity of Lake of Dreams was shattered by the effects of the
                disease of alcoholism and the site of a “bottom” (when your life
                is falling apart faster than you can lower your standards).
              </p>
              <p className="my-4 text-slate-500 text-lg leading-relaxed">
                What at the time seemed like the worst thing to happen, has
                turned out to be the best thing for our entire family as the 12
                step program of recovery has come to permeate and underpin the
                values and principles our entire family works to be each and
                every day. To build on the positive and spiritual energy that
                began with the 2003 bottom, our family is passionate about
                sharing Lake of Dreams with those seeking recovery. To this end,
                we are most pleased that men and women are willing to take a
                weekend of time in the Spring and Fall to come to Lake of Dreams
                for a weekend retreat for spiritual recovery through the 12 step
                programs. We are most grateful to the men and women who
                participate in the group conscience to ensure these retreats
                function and serve their purpose of helping people with their
                recovery. Our family is also passionate about Lake of Dreams
                hosting an annual picnic for people in recovery on the Saturday
                before Father’s Day.
              </p>
              <p className="my-4 text-slate-500 text-lg leading-relaxed">
                This is a special day where families of people in recovery come
                to engage in a host of family activities and together, show that
                families can have an awesome and healthy time in a clean, sober
                environment. This day also includes speakers from AA, Al-Anon
                and Alateen. It is truly a special day and all are welcome to
                attend with family and friends. Our family has taken steps to
                ensure that Lake of Dreams is available for these retreats and
                annual picnic for as long as possible. History of the Recovery
                RetreatsHistory of the Recovery Retreats The first retreat was
                held at Lake of Dreams in the Fall of 2007. The idea started
                when a few men from a meeting were helping a fellow member
                dispose of a large pile of logs that had been left by the
                utility that had taken them down after a severe ice storm that
                damaged power lines in her yard. I had agreed to dispose of the
                logs as we had use as firewood at Lake of Dreams and asked these
                men to help me load the vehicles. While loading, one of the men
                asked what I was going to do with all this wood at the lake. I
                spontaneously responded with “let’s have a retreat at Lake of
                Dreams”.
              </p>
              <p className="my-4 text-slate-500 text-lg leading-relaxed">
                One of the men immediately responded that he would organize the
                retreat and his efforts led to that first retreat. The format
                was stolen from a long serving men’s retreat held near Sullivan,
                Missouri. The key traditions of the Lake of Dreams retreats
                include: 1). both AA & Al-Anon format meetings 2). working
                retreat to give every person an opportunity to be of service to
                others 3). scholarships for those unable to afford the cost of
                attending and 4). attendees commit to attend the full retreat
                5). only requirement for participation is a desire to improve
                one’s life through a program of recovery 6). governed by a group
                conscious who serve the members that desire to attend.
              </p>
              <p className="my-4 text-slate-500 text-lg leading-relaxed">
                In 2012, the number of retreats were expanded to 2 each Spring
                and Fall to keep the groups to a more intimate number of
                approximately 25 men. Recently, a third retreat was added for
                the same purpose. The first women’s retreat was held in the
                Spring of 2013 and a second retreat was recently added as well.
                The group conscience coordinate scheduling of the retreats with
                the family and ensure that the premises and property are left in
                the best condition possible. Spiritual Progress Having attended
                most of the retreats and all of the family picnics, we have seen
                many people enjoy significant spiritual awakenings and a few
                spiritual experiences during the course of the retreat. We are
                convinced that the environment of witnessing 25 people engaged
                in spiritual action – from those who lead the retreats,
                participate in the group conscience, lead meetings, speak, cook,
                clean-up, help another member who may be struggling, share or
                otherwise be a good example of the spiritual principles of the
                program – is what leads to awesome progress for many. We are
                grateful for those men and women who desire to share in the
                experience of Lake of Dreams retreats and thereby contribute to
                the spiritual energy that is replacing the negative and
                destructive energy that existed prior to recovery. Our desire is
                to see this spiritual energy build for generations to come.
              </p>
              <p className="my-4 text-slate-800 text-lg leading-relaxed font-semibold">
                Paul D. Melnuk Recovery Capital
              </p>
            </div>
            <div className="p-4 border-t flex justify-end">
              <button
                onClick={closeModal}
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-300"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </section>
  );
}

export default About;
