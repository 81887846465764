import React from "react";
import "./footer.css";

function Footer() {
  return (
    <section className="bg-secondary-focus mt-20 pt-5 pb-5 glass">
      <div className="container px-10 mx-auto">
        <div className="flex justify-center space-x-9">
          <div className="flex">
            <h3 className="font-bold text-3xl">Lake of Dreams ©2024</h3>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Footer;
