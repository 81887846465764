import React, { useState, useEffect } from "react";
import "./nav.css";
import logo from "../../assets/header.png";
import AOS from "aos";
import "aos/dist/aos.css";

function Nav() {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    AOS.init();

    const handleScroll = () => {
      setIsSticky(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <nav
      className={`container mx-auto p-6 rounded-lg ${
        isSticky ? "sticky scrolled" : ""
      }`}
      data-aos="fade-down"
      data-aos-duration="800"
    >
      <div className="flex justify-between fade-up">
        <img
          src={logo}
          alt="Lake of Dreams Logo"
          className="h-10 w-auto mr-3 hover:scale-110 transition duration-300 ease-in-out"
          data-aos="zoom-in"
          data-aos-delay="200"
        />
        <div className="md:flex space-x-9 hidden">
          <a
            href="#home"
            className="hover:text-blue-800 duration-300 hover:scale-110"
            data-aos="fade-left"
            data-aos-delay="300"
          >
            Home
          </a>
          <a
            href="#about"
            className="hover:text-blue-800 duration-300 hover:scale-110"
            data-aos="fade-left"
            data-aos-delay="400"
          >
            About
          </a>
          <a
            href="#retreats"
            className="hover:text-blue-800 duration-300 hover:scale-110"
            data-aos="fade-left"
            data-aos-delay="400"
          >
            Retreats
          </a>
          <a
            href="#picnic"
            className="hover:text-blue-800 duration-300 hover:scale-110"
            data-aos="fade-left"
            data-aos-delay="400"
          >
            Picnic
          </a>
          <a
            href="#contact"
            className="hover:text-blue-800 duration-300 hover:scale-110"
            data-aos="fade-left"
            data-aos-delay="400"
          >
            Contact
          </a>
        </div>

        {/* Mobile */}
        <div className="dropdown md:hidden">
          <label tabIndex="0" className="btn btn-ghost btn-circle">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h7"
              />
            </svg>
          </label>
          <ul
            tabIndex="0"
            className="menu menu-vertical dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box right-9"
          >
            <li>
              <a href="#home" className="hover:text-primary">
                Home
              </a>
            </li>
            <li>
              <a href="#about" className="hover:text-primary">
                About
              </a>
            </li>
            <li>
              <a href="#retreats" className="hover:text-primary">
                Retreats
              </a>
            </li>
            <li>
              <a href="#picnic" className="hover:text-primary">
                Picnic
              </a>
            </li>
            <li>
              <a href="#contact" className="hover:text-primary">
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Nav;
