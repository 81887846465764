import React from "react";
import Nav from "./components/nav/Nav";
import Hero from "./components/hero/Hero";
import About from "./components/about/About";
import Retreats from "./components/retreats/Retreats";
import Picnic from "./components/picnic/Picnic";
import Contact from "./components/contact/Contact";
import Footer from "./components/footer/Footer";

function App() {
  return (
    <div className="">
      <Nav />
      <Hero />
      <About />
      <Picnic />
      <Retreats />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
